<template>
    <template-layout>
        <template slot="menu">
            <reports-menu />
        </template>

        <template slot="content">
            <v-container class="py-6">
                <v-row align="center">
                    <v-col cols="6">
                        <h1 class="headline">Mapa de Pedidos</h1>
                    </v-col>

                    <v-spacer></v-spacer>

                    <v-col>
                        <v-row class="justify-end">
                        </v-row>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" sm="3" lg="2"></v-col>
                        <v-menu v-model="dateMenu"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                min-width="290px">
                            <template v-slot:activator="{ on }">
                                <v-text-field label="Fecha"
                                              color="accent"
                                              v-model="selectedDate"
                                              prepend-icon="mdi-calendar"
                                              readonly
                                              v-on="on"
                                ></v-text-field>
                            </template>

                            <v-date-picker v-model="selectedDate"
                                           @input="dateMenu = false"
                                           no-title
                                           :first-day-of-week="1"
                                           locale="es-AR"
                                           event-color="green lighten-1">
                            </v-date-picker>
                        </v-menu>
                    </v-col>

                    <v-col cols="12" sm="3" lg="2">
                        <v-select
                            label="Móvil"
                            prepend-icon="mdi-truck"
                            v-model="selectedVehicle"
                            :items="optionsVehicle"
                            item-text="name"
                            item-value="id"
                            required
                        >
                        </v-select>
                    </v-col>

                    <v-spacer></v-spacer>

                    <v-col cols="12" sm="2" class="text-end">
                        <h5 class="font-weight-regular grey--text">Pedidos</h5>
                        <span class="display-1">{{ filteredOrders.length }}</span>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <div class="google-map" style="height: 900px;" id="directions-map"></div>
                    </v-col>
                </v-row>
            </v-container>
        </template>
    </template-layout>
</template>

<script>
    /* global google */
    import TemplateLayout from '@/layout/TemplateLayoutWithMenu';
import ReportsMenu from './ReportsMenu.vue';

    export default {
        components: {
    TemplateLayout,
    ReportsMenu
},

        data() {
            return {
                selectedDate: this.$moment().format('YYYY-MM-DD'),
                dateMenu: false,
                selectedVehicle: 0,

                startLocation: new google.maps.LatLng(-34.4990746, -59.0867709),
                orders: [],
                markers: []
            };
        },

        computed: {
            isLoading() {
                return this.$store.getters['order/isLoading']();
            },

            optionsVehicle() {
                return [
                    { id: 0, name: 'Todos' },
                    ...this.orders.map(order => order.vehicle).filter((v, i, self) => {
                        return self.indexOf(v) === i;
                    })
                ];
            },

            filteredOrders() {
                return this.orders.filter((value, index, self) => {
                    return this.selectedVehicle === 0 || this.selectedVehicle === value.vehicle.id;
                });
            }
        },

        watch: {
            selectedDate: {
                immediate: true,
                async handler(val) {
                    this.loadingRoutes = true;

                    let result = await this.$store.dispatch('order/fetchAll', {
                        deliveryDate: this.selectedDate,
                        fields: 'id,state,shippingAddress.location.latitude,shippingAddress.location.longitude,vehicle.id,vehicle.name',
                        limit: 1000
                    });

                    this.orders = result.ids.map(id => this.$store.getters['order/getById'](id));

                    this.loadingRoutes = false;
                }
            },

            filteredOrders(val) {
                for(let m of this.markers) {
                    m.setMap(null);
                }
                this.markers = [];

                for(let order of val) {
                    let marker = new google.maps.Marker({
                        map: this.map,
                        position: new google.maps.LatLng(order.shippingAddress.location.latitude, order.shippingAddress.location.longitude),
                        icon: {
                            path: google.maps.SymbolPath.CIRCLE,
                            scale: 8.5,
                            fillColor: '#F00',
                            fillOpacity: 0.4,
                            strokeWeight: 0.4
                        }
                    });
                    this.markers.push(marker);
                }
            }
        },

        mounted() {
            let date = this.$route.query.date ? this.$route.query.date : null;
            if(date) {
                this.selectedDate = date;
            }

            this.map = new google.maps.Map(document.getElementById('directions-map'), {
                zoom: 7,
                center: this.startLocation
            });
        },

        methods: {
            updateRouteQuery() {
                let q = {};

                if(this.selectedDate) {
                    q.date = this.selectedDate;
                }

                this.$router.replace({ name: 'deliveryRoutesList', query: q }).catch(() => {});
            },

            onFiltersSelected() {

            }
        }
    };
</script>

<style lang="scss" scoped>
</style>
