<template>
    <v-list dense color="transparent" class="mt-4">
        <v-list-item-group color="primary">
            <v-list-item v-for="(item, i) in children" :key="i" :to="{ name: item.route }">
                <!-- <v-list-item-icon>
                    <v-icon color="grey lighten-2" v-text="item.icon"></v-icon>
                </v-list-item-icon>-->
                <v-list-item-content>
                    <v-list-item-title v-text="item.name"></v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list-item-group>
    </v-list>
</template>


<script>
export default {
    components: {},

    data() {
        return {
            children: [
                { name: 'Mapa de Pedidos', route: 'ordersHeatMap' },
                { name: 'Reporte OIA', route: 'reportsOIA' }
            ]
        };
    }
};
</script>