<template>
    <template-layout>
        <template slot="drawers">
            <slot name="drawers"></slot>
        </template>

        <template slot="content">
            <v-container>
                <v-row>
                    <v-col cols="2">
                        <slot name="menu"></slot>
                    </v-col>

                    <v-col cols="10">
                        <slot name="content"></slot>
                    </v-col>
                </v-row>
            </v-container>
        </template>
    </template-layout>
</template>

<script>
import TemplateLayout from '@/layout/TemplateLayout.vue';

export default {
    components: {
        TemplateLayout,
    },
};
</script>

<style lang="scss" scoped>
</style>
